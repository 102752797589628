<template>
	<div class="bigScreen">
		<!-- 顶部区域 -->
		<div class="big-top" v-if="bigTitleIsShow">
			<div class="top-title-t">
				<i class="icon-two"></i>湖南96320非急救转运调度中心
			</div>
			<marquee scrollamount="6" class="marqueeTitle" onmouseover="this.stop()" onmouseout="this.start()"
				behavior="scroll">
				<span class="top-title" v-for="item in titleList" :key="item.uuid">{{item.remark}}</span>
			</marquee>
		</div>
		<div class="big-content">
			<!-- 左侧区域 -->
			<div class="big-content-left">
				<div class="left-top">
					<div class="carSum">车辆总数&nbsp;{{carNumForm.totalCarNum}}&nbsp;辆</div>
					<div class="carOnline">
						<div>在线数量&nbsp;{{carNumForm.normalCarNum}}&nbsp;(辆)</div>
						<div>在线率&nbsp;{{ carNumForm.onlineRate }}</div>
					</div>
					<div class="carAwait">
						<div>待命数量&nbsp;{{carNumForm.prepareNum}}&nbsp;(辆)</div>
						<div class="worrkSum">已派数量&nbsp;{{carNumForm.dispatchNum}}&nbsp;(辆)</div>
					</div>
				</div>
				<div class="tree-title">
					<i></i>机构列表
				</div>
				<div class="tree">
					<el-tree :props="defaultProps" :load="loadNode" lazy node-key="uuid" :default-expanded-keys="[17]"
						@node-click="handleNodeClick" @node-expand="nodeExpand" accordion>
						<span slot-scope="{ node, data }">
							<!-- <i :class="data.icon" v-if="node.level===4"></i> -->
							<span v-if="node.level===1">{{data.name}}</span>
							<span v-else-if="node.level===2">{{data.cityName}}</span>
							<span v-else-if="node.level===3">{{data.province}}</span>
							<span v-else-if="node.level===4">{{data.companyName}}</span>
							<span v-else :class="'cartree'+data.onStatus">
								<i></i>
								{{data.carNum}}
							</span>
						</span>
					</el-tree>
				</div>
			</div>
			<!-- 中部地图 -->
			<div class="big-content-main" v-loading="loading" element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<!-- 监控窗口区域 -->
				<div class="playfather" v-show="jk" id="playfather">
					<span class="title">预览画面</span>
					<span class="car">车牌号&nbsp;{{car}}</span>
					<div id="playWnd" class="playWnd">
						<button class="closeplayWnd" @click="closeplayWnds()"></button>
					</div>
				</div>
				<!-- 切换按钮 -->
				<i class="qiehuanbtn" @click="setZoom(ps, 'map')"></i>
				<!-- 提示组件 -->
				<div class="tooltip">
					<div class="success">
						<i></i>正常
					</div>
					<div class="stop">
						<i></i>停用
					</div>
					<div class="work">
						<i></i>任务中
					</div>
					<div class="warning">
						<i></i>待消毒
					</div>
				</div>
				<!-- 路径规划弹出框 -->
				<el-dialog :visible.sync="mapVisiable" class="mapVisiable" :title="trackCarNum" @close="mapLdes()"
					:close-on-click-modal="false">
					<div id="mapCon"></div>
					<span slot="footer" class="dialog-footer">
						<el-button type="primary" @click="mapVisiable = false">结束跟踪</el-button>
					</span>
				</el-dialog>
				<!-- 地图盒子 -->
				<div id="mapContainer"></div>
				<!-- 信息窗体弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="orderDialog" :modal="false">
					<el-tabs v-model="activeName">
						<el-tab-pane label="车辆信息" name="first">
							<div class="carInfo">
								<p>车牌号：{{car}}</p>
								<p>所属单位：{{comPany}}</p>
								<p>车速：{{gpsForm.gps.speed}}KM/h</p>
								<p>方向角：{{gpsForm.gps.direction}}°</p>
								<p>GPS方向时间：{{gpsForm.gps.collectTime}}</p>
								<p>经纬度：{{ parseFloat(gpsForm.gps.longitude).toFixed(2)}},{{ parseFloat(gpsForm.gps.latitude).toFixed(2)}}
								</p>
								<p>地理位置：{{nowAdresss}}</p>
							</div>
						</el-tab-pane>
						<el-tab-pane label="随车人员信息" name="third">
							<div>
								<div class="peoInfo" v-for="(a,idoc) in driver" :key="idoc+'a'">
									<p>驾驶员：{{a.docName}}</p>
									<p>电话:{{a.phone}}</p>
								</div>
								<div class="peoInfo" v-for="(b,inur) in doc" :key="inur+'b'">
									<p>随车医生：{{b.docName}}</p>
									<p>电话:{{b.phone}}</p>
								</div>
								<div class="peoInfo" v-for="(c,inur) in nur" :key="inur+'c'">
									<p>随车护士：{{c.docName}}</p>
									<p>电话:{{c.phone}}</p>
								</div>
								<div class="peoInfo" v-for="(d,iremove) in remove" :key="iremove+'d'">
									<p>搬抬人员：{{d.docName}}</p>
									<p>电话:{{d.phone}}</p>
								</div>
								<div class="peoInfo" v-for="(e,iescort) in escort" :key="iescort+'e'">
									<p>护送人员：{{e.docName}}</p>
									<p>电话:{{e.phone}}</p>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="设备信息" name="fourth">
							<div class="facilityInfo">
								<div>
									<p>车载终端名称：{{facility.terminalName || '暂未绑定终端'}}</p>
									<p>车载SIM卡ID：{{facility.simId}}</p>
								</div>
								<div>
									<p>车载SIM卡电话号码：{{facility.simPhone}}</p>
									<p>车载平板名称：{{facility.padName}}</p>
								</div>
								<div>
									<p>车载摄像头4远程URL：{{facility.camera4Url}}</p>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div class="btns">
						<button class="look" @click="preview()">
							<i class="look_icon"></i>
							<p>预览</p>
						</button>
						<!-- <button class="follow" @click="tracking()">
							<i class="follow_icon"></i>
							<p>跟踪</p>
						</button> -->
						<button class="follow" @click="gbpreview()">
							<i class=""></i>
							<p>关闭</p>
						</button>
					</div>
				</el-dialog>
				<!-- 监控弹出框 -->
				<el-dialog :visible.sync="pluginVisiable" class="pluginVisiable" :modal="false">
					检测到未安装插件，请先安装插件&nbsp;&nbsp;
					<!-- <a href="../HATWebCtrlPlugin.exe" download="HATWebCtrlPlugin.exe"
						@click="pluginVisiable=false">点击下载安装</a> -->
                    <a href="https://zyc.hn12320.cn/HATWebCtrlPlugin.exe" download="HATWebCtrlPlugin.exe"
						@click="pluginVisiable=false">点击下载安装</a>
				</el-dialog>
			</div>
			<!-- 右侧列表 -->
			<div class="big-content-right">
				<div class="right-title">
					<div class="right-title-top">
						<i class="big-show"
							@click="bigTitleIsShow = !bigTitleIsShow">{{bigTitleIsShow ? '隐藏' : '显示'}}</i>
						<h1>当前转运车辆</h1>
						<i class="qiehuan" @click="$router.push('/system')"></i>
					</div>
					<!-- 搜索框 -->
					<div class="search">
						<el-input v-model.trim="searchForm" @clear="searchCar(searchForm)" placeholder="请输入车辆对应电话号码" clearable
							suffix-icon="el-icon-search" @focus="active2='two'">
							<!-- <el-button type="primary" slot="append" @click="search()">查询</el-button> -->
						</el-input>
						<el-button type="primary" @click="searchCar(searchForm)">查询</el-button>
						<!-- <el-button type="primary" @click="searchCar(searchForm)">切换</el-button> -->
					</div>
				</div>
				<!-- 车辆列表区域 -->
				<el-tabs v-model="active2" class="carList">
					<el-tab-pane label="任务中" name="one">
						<div class="onlineCarList" v-for="(item,index) in workCarList" :key="index"
							@click="precise(item)">
							<p :class="'right'+item.carOnline.onStatus">{{item.carOnline.carNum}}</p>
							<p :class="'right'+item.carOnline.onStatus">{{item.carOnline.simPhone}}</p>
						</div>
                        <!-- 这个分页仅仅是固定宽度 -->
                        <div class="fictitious-page">
                            <el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
							:current-page="paging.PageNo" :page-sizes="[5,10,15, 18]" :page-size="paging.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                        </div>
					</el-tab-pane>
					<el-tab-pane label="所有车辆" name="two">
						<div class="onlineCarList" v-for="(item,index) in carList" :key="index" @click="precise(item)">
							<p :class="'right'+item.carOnline.onStatus">{{item.carOnline.carNum}}</p>
							<p :class="'right'+item.carOnline.onStatus">{{item.carOnline.simPhone}}</p>
						</div>
						<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
							:current-page="paging.PageNo" :page-sizes="[5,10,15, 18]" :page-size="paging.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
					</el-tab-pane>
				</el-tabs>

				<div class="Nowtime">{{times}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import loginVue from '../login.vue'
import store from '@/store/index.js'
import closeImg from '@/images/close.png'

export default {
  name: 'realtime',
  components: {},
  data () {
    return {
      mapLabelArr: [],
      titleList: [],
      carNumForm: {},
      total: 0, // 总数据条数
      paging: {
        pageSize: 15, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      defaultProps: {
        label: 'name',
        children: [],
        isLeaf: 'leaf'
      }, // 树结构配置对象
      centerlongitude: 28.213754, // 地图中心经度
      centerlatitude: 112.869051, // 地图中心纬度
      searchForm: '', // 搜索表单
      carList: [],
      workCarList: [],
      times: '', // 格式化之后的当前时间
      currentTime: new Date(), // 当前时间
      infoWindowList: [], // 信息窗体列表
      markerList: [], // marker列表
      orderDialogFormVisible: false, // 弹出框
      activeName: 'first',
      active2: 'one',
      car: '',
      simPhone: '',
      comPany: '',
      facility: {
        terminalName: '',
        simId: '',
        simPhone: '',
        padName: '',
        camera4Url: ''
      },
      orderForm: {
        gps: {},
        tinfoOrder: {}
      },
      doc: [],
      nur: [],
      escort: [],
      remove: [],
      driver: [],
      circulation: '', // 车辆位置定时器
      carSumTime: '', // 车辆数量定时器
      map: '',
      trackCar: '',
      jk: false,
      cur: 0,
      clickCarNumHandle: '',
      carNumCur: '',
      form: {},
      iLastCoverLeft: 0,
      iLastCoverTop: 0,
      iLastCoverRight: 0,
      iLastCoverBottom: 0,
      width: 400,
      height: 600,
      playHeight: 600,
      // 初始化参数
      appKey: '808_RDzSpplC',
      oWebControl: null,
      WebControl: null,
      phone: '',
      gpsForm: {
        gps: {
          speed: '',
          direction: '',
          collectTime: '',
          longitude: '',
          latitude: ''
        },
        carOnline: {
          carNum: '',
          corpId: '',
          corpName: '',
          onStatus: '',
          simPhone: '',
          uuid: ''
        }
      },
      polylineLayerList: [],
      loading: false,
      maplShow: false,
      pluginVisiable: false,
      mapVisiable: false,
      isShow: true,
      trackCarNum: '123',
      ps: [],
      cenLng: '',
      cenLat: '',
      dd: 0,
      nowAdress: '',
      bigTitleIsShow: false // 顶部跑马灯是否展示
    }
  },
  computed: {
    nowAdresss: {
      get () {
        return this.nowAdress
      },
      async set () {
        await this.getGpsAddressdetail()
      }
    }
  },
  watch: {
    carList (newValue, oldValue) {
      var that = this
      this.ps = []
      Promise.all(newValue.map(async item => {
        // console.log(item.gps, item.carOnline.carNum)

        if (item.gps) {
          await that.add_allmark(item)
          this.ps.push(new BMapGL.Point(item.gps.longitude, item.gps.latitude))
        }
      })).then(() => {
        // 所有异步操作完成后的后续处理
      })
    }
  },
  created () {

  },
  mounted () {
    // const script = document.createElement('script')
    // script.src =
    //   'https://map.qq.com/api/gljs?v=1.exp&key=LDIBZ-UYHWR-D66WD-WLGIT-WU5DJ-TEFZJ'
    // document.head.appendChild(script)
    // script.onload = () => {
    this.onloadJsMet(async () => {
      this.getTimes()
      await this.createMap(this.centerlongitude, this.centerlatitude)
      await this.getAllCar()
      await this.getCarSum()
      this.detection('HATWebCtrlPlugin')
      this.getTitleMsg()
      // 创建地图

      window.that = this
      window.addEventListener('keydown', this.keydown)
      // 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
      window.that = this
      // that.initPlugin();

      $(window).resize(() => {
        if (that.oWebControl != null) {
          that.oWebControl.JS_Resize(that.width, that.height)
          that.setWndCover()
        }
      })
      // 监听滚动条scroll事件，使插件窗口跟随浏览器滚动而移动
      $(window).scroll(() => {
        if (that.oWebControl != null) {
          this.oWebControl.JS_Resize(that.width, that.height)
          this.setWndCover()
        }
      })
      this.circulator({
        level: 0
      })
      this.sjCarSum()
      // 浏览器调用WebServiceAPI需要通过Jsonp的方式，此处定义了发送JOSNP请求的函数
      window.jsonp_request = function jsonp_request (url) {
        var script = document.createElement('script')
        script.src = url
        document.body.appendChild(script)
      },
      // 定义请求回调函数
      window.cb = function cb (ret) {
      // 从结果中取出路线坐标串
        var coors = ret.result.routes[0].polyline
        var pl = []
        // 坐标解压（返回的点串坐标，通过前向差分进行压缩，因此需要解压）
        var kr = 1000000
        for (var i = 2; i < coors.length; i++) {
          coors[i] = Number(coors[i - 2]) + Number(coors[i]) / kr
        }
        // 将解压后的坐标生成LatLng数组
        for (var i = 0; i < coors.length; i += 2) {
          pl.push(new TMap.LatLng(coors[i], coors[i + 1]))
        }
        if (window.cur >= 1) {
          window.polylineLayer.setGeometries([{
            id: window.polylineLayer.id,
            paths: pl
          }])
          window.marker.setGeometries([{
            id: 'start',
            styleId: 'start',
            position: new TMap.LatLng(window.fromlatitude, window.fromlongitude)
          }])
          window.markerl.setStyles({
            start: new TMap.MarkerStyle({
              width: 44,
              height: 84,
              anchor: {
                x: 16,
                y: 32
              },
              faceTo: 'map',
              rotate: window.that.direction,
              src: require('../../images/carblue.png')
            })
          })
        } else {
          window.display_mark(ret)
          window.display_polyline(pl) // 显示路线
        }
      }
      window.display_polyline = function display_polyline (pl) {
      // 创建 MultiPolyline显示折线
        var polylineLayer = new TMap.MultiPolyline({
          id: 'polyline-layer', // 图层唯一标识
          map: window.mapl, // 绘制到目标地图
          // 折线样式定义
          styles: {
            style_blue: new TMap.PolylineStyle({
              color: '#3777FF', // 线填充色
              width: 6, // 折线宽度
              borderWidth: 5, // 边线宽度
              borderColor: '#FFF', // 边线颜色
              lineCap: 'round' // 线端头方式
            })
          },
          // 折线数据定义
          geometries: [{
            id: 'pl_1', // 折线唯一标识，删除时使用
            styleId: 'style_blue', // 绑定样式名
            paths: pl
          }]
        })
        window.polylineLayer = polylineLayer
      }
      window.initMapL = function initMapL (flatitude, flongitude, tlatitude, tlongitude) { // 程序入口
      // WebServiceAPI请求URL（驾车路线规划默认会参考实时路况进行计算）
        var url = 'https://apis.map.qq.com/ws/direction/v1/driving/' // 请求路径
        url += '?from=' + flatitude + ',' + flongitude // 起点坐标
        url += '&to=' + tlatitude + ',' + tlongitude // 终点坐标
        url += '&output=jsonp&callback=cb' // 指定JSONP回调函数名，本例为cb
        url += '&key=LDIBZ-UYHWR-D66WD-WLGIT-WU5DJ-TEFZJ' // 开发key，可在控制台自助创建
        // 发起JSONP请求，获取路线规划结果
        window.jsonp_request(url)
      }
      //   // 标注点
      window.display_mark = this.display_mark
    })

    // }
  },
  // 在Vue实例销毁前，清除我们的定时器
  beforeDestroy () {
    clearInterval(this.getTimesInterval)
    clearInterval(window.gjtimer)
    clearInterval(this.circulation)
    clearInterval(this.carSumTime)
    if (window.mapl) {
      window.mapl.destroy()
      window.mapl = null
    }
  },
  destroyed () {
    this.closeWindow()
  },
  methods: {
    async onloadJsMet (callBack, src = 'https://api.map.baidu.com/api?v=1.0&&type=webgl&ak=QOLVNYE4526LLXiE11B6aRc0I6odGGMp') {
      const script = document.createElement('script')
      script.src = src
      document.head.appendChild(script)
      script.onload = callBack
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击左侧树节点触发
    async handleNodeClick (data, node) {
    //   console.log(data, node, 88888)
      if (node.level == 5) {
        clearInterval(this.circulation)
        this.getOnlyAllCar(data.simPhone)
        this.circulator(node)
        window.cur = that.cur
        window.map.setZoom(15)
      }
    },
    // 节点展开触发
    async nodeExpand (data, node, res) {
      // 为4即机构名称
      if (node.level === 4) {
        // clearInterval(this.circulation)
        // window.map.destroy()
        // this.markerList = []
        // this.infoWindowList = []
        // //  创建地图
        // that.createMap(this.centerlongitude, this.centerlatitude)
        // this.getCompanyAllCar(data.uuid)
        // node.uuid = data.uuid
        // this.circulator(node)
      }
    },
    // 获取省份--调试完成
    loadNode (node, resolve) {
      // 如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve)
      }
      // 如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve)
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve)
      }
      if (node.level == 3) {
        this.getorderCompany(node, resolve)
      }
      if (node.level == 4) {
        this.getVehicleList(node, resolve)
      }
      if (node.level == 5) {
        resolve([])
      }
    },
    async getProvince (resolve) {
      const {
        data: res
      } = await this.$http.post(
        '/userManageServer/area/selectProvinceList', {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')

      res.data.forEach(item => {
        item.name = item.province
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      resolve(res.data)
    },
    // 获取市--调试完成
    async getCity (node, resolve) {
      const {
        data: res
      } = await this.$http.post(
        '/userManageServer/area/selectCityList', {
          province: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')
      res.data.forEach(item => {
        item.name = item.cityName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      resolve(res.data)
    },
    // 获取区域--调试完成
    async getDistrict (node, resolve) {
      const {
        data: res
      } = await this.$http.post(
        '/userManageServer/area/selectDistrictList', {
          cityId: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')

      res.data.forEach(item => {
        item.name = item.disName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      resolve(res.data)
    },
    // 按地区获取机构--调试完成
    async getorderCompany (node, resolve) {
      const {
        data: res
      } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
          district: node.data.uuid,
          headers: {
            uuid: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'company'
          }
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')

      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
        item.name = item.companyName
      })

      resolve(res.data)
    },
    // 按机构获取车辆信息
    async getVehicleList (node, resolve) {
    //   console.log('车辆信息')
      this.corpId = node.data.uuid
      const {
        data: res
      } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleListDataAuthScope', {
          corpId: node.data.uuid,
          pageNo: 1,
          pageSize: 1000,
          headers: {
            CORP_ID: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'vehicle'
          }
        }
      )
      if (res.statusCode !== '200') {
        return this.$message.error('获取车辆信息失败')
      }
      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
        item.name = item.carNum
      })
      resolve(res.data)
    },
    async getSimpledVehicleList (node) {
      this.corpId = node.uuid
      const {
        data: res
      } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleList', {
          corpId: node.uuid
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('获取车辆信息失败') }
      // this.tabData = res.data;
      const list = res.data
      return list
      // this.total = +res.pageBean.pageDataCount;
    },
    // 获取轨迹
    async getlocation (e, v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleGps', {
        carNum: e
      })
      if (res.statusCode !== '200') {
        console.log('GG')
        return this.$message.info('车辆未启动或GPS信号弱，未获取到车辆位置及相关信息，请联系相关司机')
      }
      if (res.data.gps.length == 0) {
        clearInterval(window.gjtimer)
        // this.createmal(this.centerlongitude, this.centerlatitude)
        return this.$message.info('车辆未启动或GPS信号弱，未获取到车辆位置及相关信息，请联系相关司机')
      }
      if (res.data.tinfoOrder === null) {
        clearInterval(window.gjtimer)
        return this.$message.info('未获取到订单信息，请联系平台管理员')
      }
      if (!res.data.tinfoOrder.orderNo) {
        clearInterval(window.gjtimer)
        return this.$message.info('未获取到订单信息，请联系平台管理员')
      }
      this.mapVisiable = true
      window.fromlongitude = +res.data.gps[0].longitude
      window.fromlatitude = +res.data.gps[0].latitude
      window.tolongitude = +res.data.tinfoOrder.endLongitude
      window.tolatitude = +res.data.tinfoOrder.endLatitude
      window.rotate = res.data.gps[0].direction
      if (window.mapl) {
        window.mapl.destroy()
      }
      this.createmal(window.fromlatitude, window.fromlongitude)
      window.initMapL(window.fromlatitude, window.fromlongitude, window.tolatitude, window.tolongitude)
      var p1 = new TMap.LatLng(window.fromlatitude, window.fromlongitude)
      var p2 = new TMap.LatLng(window.tolatitude, window.tolongitude)
      var ps = [p1, p2]
      this.setZoom(ps, 'mapl')
    },
    // 获取所有在线车辆
    async getAllCar () {
      const that = this
      const num = 0
      const {
        data: ress
      } = await this.$http.post('/pmService/tInfoVehicle/getAllGpsOnline', {
        paramsType: '1',
        screenPar: this.searchForm,
        headers: {
          CORP_ID: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'vehicle'
        }
      })
      //   ress.data.forEach((item) => {
      //     if (item.gps != null) {
      //       num++
      //     }
      //   })
      this.workCarList = ress.data.filter(item => {
        // console.log(item.carOnline.carNum, '我是是是是')
        if (item.carOnline.onStatus === '3' || item.carOnline.onStatus === '5') {
        //   console.log(item.carOnline.carNum, 'itemitem')
          that.add_allmark(item)
          return item
        }
      })
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getAllGpsOnline2', {
        screenPar: this.searchForm,
        paramsType: '1',
        pageSize: that.paging.pageSize,
        pageNo: that.paging.PageNo,
        headers: {
          CORP_ID: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'vehicle'
        }
      })
      if (res.statusCode !== '200') return this.$message.error('车辆未启动或GPS信号弱，获取车辆位置失败')
      //   this.total = num
      this.total = res.pageBean.pageDataCount * 1
      // this.carList = ""
      //   this.carList = res.data.filter(item => item.gps !== null)
      this.carList = res.data
      //   console.log(this.carList, '切换分页，刷当前车辆数据', this.gpsForm)
      //   console.log(this.workCarList, 'this.workCarList1')
      //   this.workCarList = res.data.filter(item => item.carOnline.onStatus === '3')
      if (this.gpsForm.carOnline && ress.data.length != 0) {
        if (this.gpsForm.carOnline.carNum !== '') {
          const obj = ress.data.filter(item => {
            return item.carOnline.carNum === this.gpsForm.carOnline.carNum
          })[0]
          if (obj) {
            this.gpsForm = obj
          }
        }
      }
    },
    // 获取机构下所有车辆
    async getCompanyAllCar (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getAllGpsOnline', {
        paramsType: '2',
        corpId: v
      })
      if (res.statusCode !== '200') return this.$message.error('车辆未启动或GPS信号弱，获取车辆位置失败')
      this.carList = res.data.filter(item => item.gps !== null)
      this.workCarList = res.data.filter(item => {
        return item.carOnline.onStatus === '3' || item.carOnline.onStatus === '5'
      })
      if (this.gpsForm.carOnline.carNum !== '') {
        const obj = res.data.filter(item => item.carOnline.carNum === this.gpsForm.carOnline.carNum)[0]
        if (obj) {
          this.gpsForm = JSON.parse(JSON.stringify(obj))
        }
      }
      this.setZoom(this.ps, 'map')
    },
    // 获取指定车辆
    async getOnlyAllCar (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getAllGpsOnline', {
        paramsType: '3',
        simPhone: v
      })
      //   console.log(res)
      if (res.data[0] === undefined) return this.$message.error('该车未绑定SIM卡,请联系管理员绑定')
      if (res.statusCode !== '200') return this.$message.error('车辆未启动或GPS信号弱，获取车辆位置失败')
      if (res.data[0].gps === null) return this.$message.error('车辆未启动或GPS信号弱，获取车辆位置失败')
      //   this.workCarList = res.data.filter(item => item.carOnline.onStatus === '3')
      const index = this.markerList.findIndex(item => {
        return item.id === res.data[0].carOnline.simPhone
      })
      const infoWindowIndex = this.infoWindowList.findIndex(item => {
        return item.imgSimId === res.data[0].carOnline.simPhone
      })
      const { lat, lng } = await this.conVertor(res.data[0].gps.longitude, res.data[0].gps.latitude)
      //   this.markerList[index].setGeometries([{
      //     // id: this.markerList[index].id,
      //     id: this.markerList[index].geometries[0].id,
      //     styleId: 'start',
      //     position: new TMap.LatLng(res.data[0].gps.latitude, res.data[0].gps.longitude)
      //   }])
      //   this.infoWindowList[index].setPosition(new TMap.LatLng(res.data[0].gps.latitude, res.data[0].gps
      //     .longitude))
      //   this.markerList[index].setStyles({
      //     start: new TMap.MarkerStyle({
      //       width: 44,
      //       height: 84,
      //       anchor: {
      //         x: 16,
      //         y: 32
      //       },
      //       faceTo: 'map',
      //       rotate: 360 - res.data[0].gps.direction,
      //       src: require('../../images/carblue.png')
      //     })
      //   })
      //   console.log(this.infoWindowList)
      this.workCarList.forEach((item) => {
        if (item.carOnline.simPhone == v) {
        //   console.log('我来了', item)sss
          item.gps.longitude = res.data[0].gps.longitude
          item.gps.latitude = res.data[0].gps.latitude
        }
      })
      if (infoWindowIndex > -1) {
        this.infoWindowList[infoWindowIndex].setPosition(new BMapGL.Point(lng, lat))
      }
      this.markerList[index].setPosition(new BMapGL.Point(lng, lat))
      if (this.gpsForm.carOnline.carNum !== '') {
        this.gpsForm = res.data[0]
      }
      window.map.setZoom(15)
      window.map.setCenter(new BMapGL.Point(lng, lat))
    },
    // 车辆数量
    async getCarSum () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getCarNum',
        {
          headers: {
            CORP_ID: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'vehicleStatus'
          }
        }
      )

      if (res.statusCode !== '200') return this.$message.error('获取车辆数量信息失败')
      this.carNumForm = res.data
    },
    conVertor (x, y) {
      return new Promise((resolve, reject) => {
        var ggPoint = new BMapGL.Point(x, y)
        var convertor = new BMapGL.Convertor()
        var pointArr = []
        pointArr.push(ggPoint)
        convertor.translate(pointArr, 3, 5, data => {
          var result = {
            lat: data.points[0].lat,
            lng: data.points[0].lng
          }
          resolve(result)
        })
      })
    },
    addIcon (map, log, lat, img, width, height, rotate, isCenter, anchorX, anchorY, opt) {
      return new Promise((resolve, reject) => {
        this.conVertor(log, lat)
          .then(data => {
            // console.log(data, 'datadata')
            const point = new BMapGL.Point(data.lng, data.lat)
            const myIcon = new BMapGL.Icon(img, new BMapGL.Size(width, height), {
              anchor: new BMapGL.Size(anchorX, anchorY)
            })
            const marker1 = new BMapGL.Marker(point, { icon: myIcon })
            marker1.id = opt.carOnline.simPhone

            rotate && marker1.setRotation(rotate)
            // console.log(map, marker1, 'marker1marker1')

            map.addOverlay(marker1)
            if (isCenter) {
              map.panTo(point)
            }

            resolve({ marker1, opt, map, point })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addText (opt, map, point, isWatch = true) {
    //   console.log(opt, 'optaddText')
      if (isWatch && this.mapLabelArr.indexOf(opt.carOnline.simPhone) > -1) {
        return false
      }
      //   console.log(opt, '来了')
      var that = this
      //   var content = `<div style="position: relative;padding:15px 15px">
      //     <h2>${opt.carOnline.carNum}</h2>
      //     <img id=${opt.carOnline.simPhone} style="
      //       position: absolute;
      //       right: 3px;
      //       top: 3px;
      //       width: 18px;
      //       height: 18px;" src=${closeImg} />
      //       </div>`
      var content = `<div style="position: relative;padding:15px 15px">
        <h2>${opt.carOnline.carNum}</h2>
       <svg id=${opt.carOnline.simPhone} style="
            position: absolute;
            right: 3px;
            top: 3px; t="1698052081245" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4093" width="18" height="18"><path d="M593.134933 512l243.370667-243.370667a57.344 57.344 0 0 0-81.1008-81.169066L512 430.865067l-243.370667-243.370667A57.344 57.344 0 0 0 187.460267 268.629333l243.370666 243.370667-243.370666 243.370667a57.344 57.344 0 0 0 81.134933 81.134933l243.370667-243.370667 243.370666 243.370667a57.344 57.344 0 0 0 81.169067-81.1008L593.134933 512z" fill="#333333" p-id="4094"></path></svg>
          </div>`
      var label = new BMapGL.Label(content, {
        position: point,
        offset: new BMapGL.Size(-40, -110)
      })
      map.addOverlay(label)
      const imgSimPhone = document.getElementById(opt.carOnline.simPhone)
      const imgSimId = imgSimPhone && imgSimPhone.id
      const mapContainer = document.getElementById('mapContainer')
      opt.textLabel = label
      opt.textLabel.imgSimId = imgSimId
      label.setStyle({
        color: '#000',
        border: '1px solid #eee'
      })
      if (this.mapLabelArr.length > 0 && this.mapLabelArr.some(item => item && item == imgSimId)) {
        // console.log(`${imgSimId} already exists in mapLabelArr`)
      } else {
        this.mapLabelArr.push(imgSimId)
      }
      mapContainer.addEventListener('click', function (event) {
        console.log(event.target.id, 'event.target.tagName')
        if (event.target.tagName === 'svg' && event.target.hasAttribute('id')) {
          const id = event.target.id
          var allOverlay = map.getOverlays()
          //   console.log(allOverlay, 'allOverlay')
          for (var i = 0; i < allOverlay.length; i++) {
            //   console.log(allOverlay[i].toString(), 'allOverlay[i].toString()')
            if (allOverlay[i].toString() == 'Label' && allOverlay[i].imgSimId && allOverlay[i].imgSimId == id) {
              const index = that.infoWindowList.findIndex(item => {
                return item.imgSimId === allOverlay[i].imgSimId
              })
              console.log(index, 'index')
              that.infoWindowList.splice(index, 1)
              console.log(that.infoWindowList, 'that.infoWindowList')
              that.map.removeOverlay(allOverlay[i])
            }
          }
        }
      })
      return label
    },
    // 创建地图
    async createMap (e1, e2) {
      //   定义地图中心点坐标
      var map = new BMapGL.Map('mapContainer') // 创建地图实例
      const { lat, lng } = await this.conVertor(e2, e1)
      //   console.log(lat, lng, 'lat, lng')
      var center = new BMapGL.Point(lng, lat) // 创建点坐标
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      map.centerAndZoom(center, 17)
      map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      map.setTilt(30)
      this.map = map
      window.map = this.map
    },
    // 创建小地图
    createmal (e1, e2) {
      // 初始化地图
      var mapl = new TMap.Map('mapCon', {
        center: new TMap.LatLng(e1, e2), // 地图显示中心点
        zoom: 10, // 设置地图缩放级别
        pitch: 30, // 设置俯仰角
        rotation: 20, // 设置地图旋转角度
        viewMode: '2D', // 设置地图视图模式
        showControl: false
      })
      window.mapl = mapl
    },
    // 小地图标记点
    display_mark (v) {
      this.addIcon(window.mapl, window.fromlongitude, window.fromlatitude, '../../images/carblue.png', 22, 42, 360 - window.rotate)
      this.addIcon(window.mapl, window.tolongitude, window.tolatitude, 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png', 22, 42)
      // //标记起终点marker
    //   var markerl = new TMap.MultiMarker({
    //     id: 'marker-layer',
    //     map: window.mapl,
    //     styles: {
    //       start: new TMap.MarkerStyle({
    //         width: 22,
    //         height: 42,
    //         anchor: {
    //           x: 16,
    //           y: 32
    //         },
    //         faceTo: 'map',
    //         rotate: 360 - window.rotate,
    //         src: require('../../images/carblue.png')
    //       }),
    //       end: new TMap.MarkerStyle({
    //         width: 22,
    //         height: 42,
    //         anchor: {
    //           x: 16,
    //           y: 32
    //         },
    //         src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
    //       })
    //     },
    //     geometries: [{
    //       id: 'start',
    //       styleId: 'start',
    //       position: new TMap.LatLng(window.fromlatitude, window.fromlongitude)
    //     }, {
    //       id: 'end',
    //       styleId: 'end',
    //       position: new TMap.LatLng(window.tolatitude, window.tolongitude)
    //     }]
    //   })
    //   window.markerl = markerl
    //   window.markerlStyle = markerl.styles
    },
    // 点击查询按钮触发
    async search () {
      // const { data: res } = await this.$http.post('', {})
      this.move_mark()
    },
    // 添加标注点
    async add_allmark (v) {
      if (v.gps === null) return
      var that = this
      // 判断该标注点是否已存在
      const index = this.markerList.findIndex(item => {
        return item.id == v.carOnline.simPhone
      })
      const inFoindex = that.infoWindowList.findIndex(item => {
        return item.imgSimId == v.carOnline.simPhone
      })
      console.log(that.infoWindowList, inFoindex, 'indexindexindex')
      const { lat, lng } = await this.conVertor(v.gps.longitude, v.gps.latitude)
      if (inFoindex != -1) {
        that.infoWindowList[inFoindex].setPosition(new BMapGL.Point(lng, lat))
      }
      //   console.log(this.mark erList, that.infoWindowList, '判断该标注点是否已存在')
      if (index != -1) {
        // console.log(that.infoWindowList, index)

        // that.infoWindowList.splice(index, 1)
        that.centerlongitude = this.markerList[index].latLng.lat
        that.centerlatitude = this.markerList[index].latLng.lng
        that.markerList[index].setPosition(new BMapGL.Point(lng, lat))
        // console.log(that.markerList, 'that.markerList')
        return false
        // 设置地图中心点及缩放
        // return window.map.setCenter(new BMapGL.Point(that.centerlatitude, that.centerlongitude))
      }
      if (!window.map) return false
      //   console.log(v.carOnline.simPhone, 'opt.carOnline.simPhone')
      const { marker1, opt, map, point } = await this.addIcon(window.map, v.gps.longitude, v.gps.latitude, require('../../images/carblue.png'), 44, 98, v.gps.direction, false, 22, 42, v)
      //   console.log(point, 'point')
      const infoWindow = await this.addText(opt, map, point)
      marker1.on('click', async () => {
        const indexs = this.infoWindowList.findIndex(item => {
          return item.imgSimId === v.carOnline.simPhone
        })
        // console.log(indexs, 'indexs')
        if (indexs > -1) return false
        const { lat, lng } = await this.conVertor(opt.gps.longitude, opt.gps.latitude)
        const infoWindow = await this.addText(opt, map, new BMapGL.Point(lng, lat), false)
        that.infoWindowList.push(infoWindow)
        // console.log(that.infoWindowList, opt.gps.longitude, opt.gps.latitude, 'optopt')
      })
      marker1.on('dblclick', function (evt) {
        that.gpsForm = v
        console.log(that.gpsForm, 'that.gpsForm')
        that.getGpsAddressdetail()
        that.phone = v.carOnline.simPhone
        that.car = v.carOnline.carNum
        that.simPhone = v.carOnline.simPhone
        that.comPany = v.carOnline.corpName
        that.trackCar = v
        that.trackCarNum = v.carOnline.carNum
        that.getorderPeo(v.carOnline.carNum)
        that.getorderFacility(v.carOnline.carNum)
        that.orderDialogFormVisible = true
        if (that.polylineLayerList[0]) {
          window.polylineLayer.remove('p1')
        }
      })
      // window.map.setCenter(that.centerlongitude, that.centerlatitude)

      that.markerList.push(marker1)
      that.infoWindowList.push(infoWindow)
    //   console.log(this.markerList, that.infoWindowList, 'index')
    },
    // 动态设置标注点位置
    move_mark (v) {},
    // 画线
    display_polyline () {
      // 创建 MultiPolyline显示折线
      var polylineLayer = new TMap.MultiPolyline({
        id: 'p1', // 图层唯一标识
        map: window.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: '#3777FF', // 线填充色
            width: 6, // 折线宽度
            borderWidth: 5, // 边线宽度
            borderColor: '#FFF', // 边线颜色
            lineCap: 'round' // 线端头方式
          })
        },
        // 折线数据定义
        geometries: [{
          id: 'p1_1', // 折线唯一标识，删除时使用
          styleId: 'style_blue', // 绑定样式名
          paths: [new TMap.LatLng(window.fromlatitude, window.fromlongitude), new TMap
            .LatLng(window.tolatitude, window.tolongitude)
          ]
        }]
      })
      window.polylineLayer = polylineLayer
      this.polylineLayerList.push(polylineLayer)
    },
    // 点击跟踪触发
    tracking () {
      this.orderDialogFormVisible = false
      this.getlocation(this.trackCar.carOnline.carNum, 1)
      this.gj()
    },
    // 设置时间
    getTimes () {
      setInterval(this.getTimesInterval, 1000)
    },
    // 设置时间
    getTimesInterval: function () {
      const _this = this
      const year = new Date().getFullYear() // 获取当前时间的年份
      const month = new Date().getMonth() + 1 // 获取当前时间的月份
      const day = new Date().getDate() // 获取当前时间的天数
      let hours = new Date().getHours() // 获取当前时间的小时
      let minutes = new Date().getMinutes() // 获取当前时间的分数
      let seconds = new Date().getSeconds() // 获取当前时间的秒数
      const wk = new Date().getDay()
      const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      const week = weeks[wk]
      // 当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      // 拼接格式化当前时间
      _this.times = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds + ' ' +
					week
    },
    // 获取车辆位置定时器
    circulator (v) {
      var that = this
      if (v.level === 4) {
        var circulation = setInterval(() => {
          that.getCompanyAllCar(v.uuid)
          console.log('机构')
        }, 15000)
        this.circulation = circulation
      } else if (v.level === 5) {
        var circulation = setInterval(() => {
          that.getOnlyAllCar(v.data.simPhone)
          console.log('指定')
        }, 15000)
        this.circulation = circulation
      } else if (v.level === 6) {
        var circulation = setInterval(() => {
          that.getOnlyAllCar(v.carOnline.simPhone)
          console.log('指定')
        }, 15000)
        this.circulation = circulation
      } else {
        var circulation = setInterval(() => {
          that.getAllCar()
          console.log('全部')
        }, 15000)
        this.circulation = circulation
      }
    },
    // 轨迹定时器
    gj () {
      var that = this
      var gjtimer = setInterval(() => {
        window.cur++
        that.getlocation(this.trackCar.carOnline.carNum)
      }, 15000)
      window.gjtimer = gjtimer
    },
    // 获取车辆数量定时器
    sjCarSum () {
      this.carSumTime = setInterval(_ => {
        this.getCarSum()
      }, 10000)
    //   this.carSumTime = time
    },
    // 获取订单随车人员
    async getorderPeo (v) {
      var that = this
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getPersonByCarnum', {
        carNum: v
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单随车人员失败')
      // console.log(res.data);
      this.doc = []
      this.nur = []
      this.escort = []
      this.driver = []
      res.data.records.forEach(item => {
        if (item.docType === '0') {
          that.doc.push(item)
        } else if (item.docType === '1') {
          that.nur.push(item)
        } else if (item.docType === '2') {
          that.escort.push(item)
        } else if (item.docType === '3') {
          that.remove.push(item)
        } else {
          that.driver.push(item)
        }
      })
    },
    // 获取设备信息
    async getorderFacility (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicleDevice/getTInfoVehicleDeviceList', {
        carNum: v
      })
      if (res.statusCode !== '200') return this.$message.error('获取设备信息失败')
      if (res.data.length > 0) {
        this.facility = res.data[0]
      }
    },
    // 监听ESC事件
    keydown (event) {
      var that = this
      this.closeWindow()
      this.jk = false
      clearInterval(window.gjtimer)
      if (window.mapl) {
        window.mapl.destroy()
        window.mapl = null
        window.cur = 0
        clearInterval(window.gjtimer)
      }
      if (event.which === 27) {
        that.orderDialogFormVisible = false
        if (that.orderDialogFormVisible = true) {
          that.orderDialogFormVisible = false
          that.driver = []
          that.doc = []
          that.nur = []
          that.remove = []
          that.escort = []
        }
      }
    },
    // 监听轨迹对话框的关闭事件
    mapLdes () {
      if (this.mapVisiable === false) {
        while (window.mapl) {
          window.mapl.destroy()
          window.mapl = null
        }
        window.cur = 0
        clearInterval(window.gjtimer)
      }
    },
    // 点击预览触发
    preview () {
      // this.detection('HATWebCtrlPlugin.exe')
      this.loading = true
      this.orderDialogFormVisible = false
      // this.jk = true
      this.initPlugin()
    },
    // 关闭
    gbpreview () {
      this.orderDialogFormVisible = false
      this.doc = []
      this.nur = []
      this.escort = []
      this.remove = []
      this.driver = []
    },
    // 点击刷新页面
    refresh () {
      window.location.reload()
    },
    // 点击安装插件
    install () {
      window.open('')
    },
    // 点击启动插件
    enablement () {},
    // 检测用户是否安装插件
    detection (name) {
      // name = name.toLowerCase();
      // for (var i = 0; i < navigator.plugins.length; i++) {
      //   if (navigator.plugins[i].name.toLowerCase().indexOf(name) > -1) {
      //     return true;
      //   }
      // }
      // return false;
      // alert(hasPlugin("Flash"));
      for (var i = 0; i < navigator.plugins.length; i++) {}
    },
    // 创建播放实例
    initPlugin () {
      var that = this
      // 初始化插件
      this.oWebControl = new WebControl({
        szPluginContainer: 'playWnd',
        iServicePortStart: 14430, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
        iServicePortEnd: 14439, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
        szClassId: '29DDBC9A-8AEA-4827-9C3A-805D697CF38F',
        cbConnectSuccess: function () {
          that.setCallbacks()

          that.oWebControl.JS_StartService('window', {
            dllPath: './WebPreview.dll'
          }).then(function () {
            that.oWebControl.JS_CreateWnd('playWnd', 0, 0).then(function () {
              that.loading = false
              that.jk = true
              that.setPalywindLayout()
              that.startPlayWindShow()
              that.oWebControl.JS_Resize(0, 0)
              // that.oWebControl.JS_CuttingPartWindow(-that.width, -that.width, that.width + 1, that.playHeight);
            })
          }, function () {
            console.log('设备已断开')
          })
        },
        cbConnectError: function (normalerror) {
          console.log('设备未安装插件' + normalerror)
          that.pluginVisiable = true
          that.loading = false
          that.oWebControl = null
          // this.$message.error("请确认本地进程是否已安装并开启成功！")
        },
        cbConnectClose: function (bNormalClose) {
          this.$message.error('车载设备已关闭，获取视频信息异常')
          that.closeplayWnds()

          that.oWebControl = null
        }
      })
    },
    // 设置窗口控制回调
    setCallbacks () {
      var that = this
      console.log('窗口回调成功')
      that.oWebControl.JS_SetWindowControlCallback({
        cbIntegrationCallBack: that.cbIntegrationCallBack
      })
    },
    // 推送消息
    cbIntegrationCallBack (oData) {
      console.log('窗口回调成功消息' + (oData.responseMsg.event))
      var that = this
      if (oData.responseMsg.event == 'startPreviewNotify') {
        console.log('重新获取海康视频地址' + oData.responseMsg.msg.devSerialNum + '频道' + oData.responseMsg.msg.channel +
						'手机号码' + that.phone)

        var phone = oData.responseMsg.msg.devSerialNum
        var channel = oData.responseMsg.msg.channel

        $.ajax({
          url: '/api/vehicleServer/HkUrl/getHkUrl?phone=' + that.phone + '&channel=' + channel,
          success: function (result) {
            var purl = result
            console.log('重新播放获取海康回调地址' + result)
            if (!purl) {
              alert('设备已关闭！')
              return
            }
            that.oWebControl.JS_RequestInterface({
              funcName: 'startPreview',
              arguments: {
                channel: channel,
                devSerialNum: that.phone,
                url: purl,
                productKey: that.productKey,
                protocol: 0,
                streamType: 1,
                code: 0
              }
            }).then(function (oData) {
              console.log(JSON.stringify(oData.responseMsg))
              // showCBInfo(JSON.stringify(oData.responseMsg));
            })
          }
        })
      }

      /* showCBInfo(JSON.stringify(oData.responseMsg)); */
    },
    // 播放窗口样式设置
    setPalywindLayout () {
      var that = this
      that.oWebControl.JS_RequestInterface({
        funcName: 'setPreviewLayout',
        arguments: {
          channels: [

            {
              channelName: '通道3',
              channel: 3
            },
            {
              channelName: '通道4',
              channel: 4
            }
          ],

          devSerialNum: that.phone,
          productKey: that.appKey,
          layout: 2
        }
      }).then(function (oData) {
        console.log('布局设置成功')
      })
    },
    // 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
    setWndCover () {
      console.log('设置窗口裁剪')
      var that = this
      const iWidth = $(window).width()
      const iHeight = $(window).height()
      const oDivRect = $('#playWnd').get(0).getBoundingClientRect()
      let iCoverLeft = (oDivRect.left < 0) ? Math.abs(oDivRect.left) : 0
      let iCoverTop = (oDivRect.top < 0) ? Math.abs(oDivRect.top) : 0
      let iCoverRight = (oDivRect.right - iWidth > 0) ? Math.round(oDivRect.right - iWidth) : 0
      let iCoverBottom = (oDivRect.bottom - iHeight > 0) ? Math.round(oDivRect.bottom - iHeight) : 0

      iCoverLeft = (iCoverLeft > that.width) ? that.width : iCoverLeft
      iCoverTop = (iCoverTop > that.playHeight) ? that.playHeight : iCoverTop
      iCoverRight = (iCoverRight > that.width) ? that.width : iCoverRight
      iCoverBottom = (iCoverBottom > that.playHeight) ? that.playHeight : iCoverBottom

      this.oWebControl.JS_RepairPartWindow(0, 0, (that.width + 1), that.playHeight) // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        that.oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, that.playHeight)
      }
      if (iCoverTop != 0) {
        that.oWebControl.JS_CuttingPartWindow(0, 0, (that.width + 1),
          iCoverTop) // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        that.oWebControl.JS_CuttingPartWindow(that.width - iCoverRight, 0, iCoverRight, that.playHeight)
      }
      if (iCoverBottom != 0) {
        that.oWebControl.JS_CuttingPartWindow(0, that.playHeight - iCoverBottom, that.width, iCoverBottom)
      }
    },
    startPlayWindShow () {
      var that = this
      var purl
      $.ajax({
        url: '/api/vehicleServer/HkUrl/getHkUrl?phone=' + that.phone + '&channel=3',
        success: function (urlstr) {
          purl = urlstr
          if (!purl) {
            that.$message.info('车载设备已关闭，获取视频信号异常')
            that.closeWindow()
            that.jk = false
            return
          }
          that.oWebControl.JS_RequestInterface({
            funcName: 'startPreview',
            arguments: {
              channel: 3,
              devSerialNum: that.phone,
              url: purl,
              productKey: that.appKey,
              protocol: 0,
              streamType: 1,
              code: 0
            }
          }).then(function (oData) {
            // showCBInfo(JSON.stringify(oData.responseMsg));
            that.oWebControl.JS_Resize(that.width, that.height)
            that.setWndCover()
            that.jk = true
          })
        }
      })
      $.ajax({
        url: '/api/vehicleServer/HkUrl/getHkUrl?phone=' + that.phone + '&channel=4',
        success: function (urlstr2) {
          purl = urlstr2
          if (!purl) {
            that.$message.info('车载设备已关闭，获取视频信号异常')
            that.closeWindow()
            that.jk = false
            return
          }
          that.oWebControl.JS_RequestInterface({
            funcName: 'startPreview',
            arguments: {
              channel: 4,
              devSerialNum: that.phone,
              url: purl,
              productKey: that.appKey,
              protocol: 0,
              streamType: 1,
              code: 0
            }
          }).then(function (oData) {
            that.oWebControl.JS_Resize(that.width, that.height)
            that.setWndCover()
            // showCBInfo(JSON.stringify(oData.responseMsg));
            that.jk = true
          })
        }
      })
    },
    // 停止全部预览功能
    stopAllPreview () {
      var that = this
      that.oWebControl.JS_RequestInterface({
        funcName: 'stopAllPreview'
      })
    },
    // 关闭视频窗口
    closeWindow () {
      var that = this
      if (that.oWebControl != null) {
        that.oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        that.stopAllPreview()
        that.oWebControl.JS_Disconnect().then(
          () => {
            // 断开与插件服务连接成功
          },
          () => {
            // 断开与插件服务连接失败
          }
        )
      }
    },
    closeplayWnds () {
      this.closeWindow()
      this.jk = false
    },
    // 点击右侧车辆列表触发
    precise (v) {
      if (v.gps) {
        v.level = 6
        clearInterval(this.circulation)
        this.getOnlyAllCar(v.carOnline.simPhone)
        this.circulator(v)
      } else {
        this.$message.error('车辆未启动或暂无此车辆，获取车辆位置失败')
      }
    },
    // 点击搜索触发
    async searchCar (v) {
      this.paging.PageNo = 1
      await this.getAllCar()
      if (!this.carList[0]) return false
      const { lat, lng } = await this.conVertor(this.carList[0].gps.longitude, this.carList[0].gps.latitude)

      if (this.carList.length != 0 && this.carList[0].gps) {
        window.map.setZoom(15)
        window.map.setCenter(new BMapGL.Point(lng, lat))
      }
    },
    setZoom (points, v) {
      var that = this
      if (points.length > 1) {
        var maxLng = points[0].lat
        var minLng = points[0].lng
        var maxLat = points[0].lat
        var minLat = points[0].lat
        var res
        for (var i = points.length - 1; i >= 0; i--) {
          res = points[i]
          if (res.lng > maxLng) maxLng = res.lng
          if (res.lng < minLng) minLng = res.lng
          if (res.lat > maxLat) maxLat = res.lat
          if (res.lat < minLat) minLat = res.lat
        };
        that.cenLng = (parseFloat(maxLng) + parseFloat(minLng)) / 2
        that.cenLat = (parseFloat(maxLat) + parseFloat(minLat)) / 2
        var zoom = that.getZoom(maxLng, minLng, maxLat, minLat, v)
      } else {
        if (points.length == 1) {
          window.map.setCenter(points[0])
          window.map.setZoom(16)
        }
      }
    },
    // 根据经纬极值计算绽放级别。
    async getZoom (maxLng, minLng, maxLat, minLat, v) {
      var zoom = ['50', '100', '200', '500', '1000', '2000', '5000', '10000', '20000', '25000', '50000',
        '100000',
        '200000', '500000', '1000000', '2000000'
      ] // 级别18到3。
      // var pointA = new TMap.LatLng(maxLat, maxLng); // 创建点坐标A
      // var pointB = new TMap.Point(minLat, minLng); // 创建点坐标B
      // var distance = TMap.geometry.calculateDistance(pointA, pointB); //获取两点距离,保留小数点后两位
      var from = minLat + ',' + minLng
      var to = maxLat + ',' + maxLng
      const {
        data: res
      } = await this.$http.get('/userManageServer/hospital/selectDistance?from=' + from + '&to=' + to)
      if (res.statusCode != 200) return this.$message.error('获取不到缩放比例')
      var that = this
      // if (data.statusCode===200) {
      var zoomLenth
      var distance = res.data
      for (var i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
        if (zoom[i] - distance > 0) {
          zoomLenth = 18 - i + 3 // 之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
          break
        }
      }
      that.centerAndZoom(new TMap.LatLng(that.cenLat, that.cenLng), zoomLenth, v)
    },
    centerAndZoom (point, zoom, v) {
      if (v === 'map') {
        window.map.setCenter(point)
        window.map.setZoom(zoom)
      } else {
        window.mapl.setCenter(point)
        window.mapl.setZoom(zoom)
      }
    },
    // 获取逆编地址信息--地址逆解析
    async getGpsAddressdetail () {
      if (this.gpsForm.gps.latitude !== '') {
        const {
          data: res
        } = await this.$http.get(`/userManageServer/hospital/selectDetailLocal?from=${this.gpsForm.gps.longitude}&to=${this.gpsForm.gps.latitude}`
        )
        this.nowAdress = res.data.formattedAddress
        return this.nowAdress
      }
    },
    async getTitleMsg () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoScreen/getTInfoScreenList', {})
      this.titleList = res.data.records
    }

  }
}
</script>
<style lang="less" scoped>
	.bigScreen {
		// position: relative;
		height: 100%;
		width: 100%;
		min-width: 100%;
		display: flex;
		flex-direction: column;

		//隐藏滚动条
		::-webkit-scrollbar {
			// display: none;
			/* Chrome Safari */
		}

		.big-top {
			flex: 1;
			width: 100%;
			height: 1.75rem;
			background-color: #000000;

			// display: flex;
			// flex-direction: column;
			// justify-content: center;
			// align-items: center;
			.top-title-t {
				padding-top: 0.125rem;
				text-align: center;
				width: 100%;
				height: 30%;
				color: red;
				font-size: 0.5rem;
				margin-bottom: 0.125rem;
				font-weight: 700;

				.icon-two {
					display: inline-block;
					position: relative;
					top: 0.125rem;
					left: 0;
					width: 1.125rem;
					height: 0.625rem;
					background: url('../../fonts/logo_red.png') no-repeat;
					background-size: contain;
					background-position: center;
					margin-right: 0.25rem;
				}
			}

			.marqueeTitle {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 70%;
				color: red;

				// -moz-user-select: none;
				// /*火狐*/
				// -webkit-user-select: none;
				// /*webkit浏览器*/
				// -ms-user-select: none;
				// /*IE10*/
				// -khtml-user-select: none;
				// /*早期浏览器*/
				// user-select: none;
				.top-title {
					font-size: 0.475rem;
					height: 1.125rem;
					font-weight: 700;
					margin-right: 7.5rem;
				}
			}
		}

		.big-content {
			overflow: hidden;
			flex: 8;
			display: flex;

			.big-content-left {
				background-color: #fafafa;
				flex: 1;
				overflow-y: scroll;

				/deep/.el-tree-node:focus>.el-tree-node__content {
					background-color: #ccc !important;
				}

				.el-tree {
					margin-top: 0.125rem;
					padding-left: 0.125rem;
					background-color: none;

					span {
						line-height: 0.325rem;
						font-size: 0.225rem;
						color: #000;
					}

					.cartree1,
					.cartree3,
					.cartree4,
					.cartree2 {
						display: flex;
						align-items: center;
						color: #0097fe;

						i {
							display: inline-block;
							width: 0.0625rem;
							height: 0.0625rem;
							background-color: #0097fe;
							border-radius: 50%;
							margin-right: 0.0625rem;
						}
					}

					.cartree2 {
						color: gray;

						i {
							background-color: gray;
						}
					}

					.cartree3 {
						color: #0097fe;

						i {
							background-color: red;
						}
					}

					.cartree4 {
						color: orange;

						i {
							background-color: orange;
						}
					}
				}

				.left-top {
					line-height: 0.425rem;
					padding-left: 0.125rem;
					margin-top: 0.125rem;
					color: #0097fe;
					font-weight: 700;
					width: 100%;

					.carSum {
						width: 100%;
						font-size: 0.375rem;
						text-align: center;
						margin-bottom: 0.1875rem;
					}

					.carOnline,
					.carAwait {
						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-bottom: 0.25rem;
						font-size: 0.25rem;
						line-height: 0.5625rem;

						div {
							flex: 1;
						}
					}
				}

				.tree-title {
					padding-left: 0.125rem;
					font-size: 0.25rem;
					color: #0097fe;
					display: flex;
					align-items: center;
					margin: 0.125rem 0;
					font-weight: 700;

					i {
						display: inline-block;
						width: 0.375rem;
						height: 0.25rem;
						background: url('../../fonts/ic_second.png') no-repeat;
						background-size: cover;
						margin-right: 0.0625rem;
					}
				}
			}

			.big-content-main {
				flex: 4;
				position: relative;
				top: 0;
				left: 0;

				#mapContainer {
					width: 100%;
					height: 100%;
				}

				/deep/ .mapVisiable {
					z-index: 999999999999999;
					position: absolute;
					top: 5%;
					left: 12%;
					width: 80%;
					height: 70%;

					.el-dialog {
						z-index: 999999999999999;
						margin: 0 !important;
						padding: 0 !important;
						width: 100%;
						height: 100%;

						.el-dialog__header {
							position: relative;
							width: 100%;
							height: 10%;
							padding: 0;
							display: flex;
							align-items: center;

							.el-dialog__headerbtn {
								position: absolute;
								top: 50%;
								right: 0.25rem;
								transform: translateY(-50%);
							}

							span {
								margin-left: 0.125rem;
							}
						}

						.el-dialog__body {
							padding: 0;
							width: 100%;
							height: 80%;

							#mapCon {
								width: 100%;
								height: 100%;
								z-index: 999999;
							}
						}

						.el-dialog__footer {
							width: 100%;
							height: 10%;
							padding: 0;

							.el-button {
								margin-top: 0.125rem;
								margin-right: 0.25rem;
							}
						}
					}
				}

				.orderDialog {
					float: left;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 999999 !important;
					width: 400px;
					height: 450px;
					border: 1px solid #63bffe;
					border-radius: 5%;

					.carInfo {
						// background-color: red;
						// width: 700;
						// height: 100%;
					}

					.btns {
						width: 100%;
						height: 88px;
						display: flex;
						line-height: 88px;
						text-align: center;

						.look,
						.follow {
							flex: 1;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							border: none;
							background-color: #63bffeff;
							color: #fff;
							outline: none;
						}

						.look_icon,
						.follow_icon {
							display: inline-block;
							width: 28px;
							height: 28px;
							margin-bottom: 5px;
						}

						.look_icon {
							background: url('../../images/ic_preview.png');
						}

						.follow_icon {
							background: url('../../images/look.png');
						}
					}

					/deep/ .el-dialog {
						width: 100%;
						height: 100%;
						margin: 0 !important;

						.el-dialog__header {
							width: 0;
							height: 0;
							display: none;
						}

						.el-dialog__body {
							padding: 0;
							width: 400px;
							height: 450px;

							.el-tabs {
								width: 400px;
								height: 362px;
								display: flex;
								flex-direction: column;
								color: #000;

								.el-tabs__header {
									padding: 0 8px;

									.el-tabs__nav-prev,
									.el-icon-arrow-left,
									.el-tabs__nav-next {
										display: none;
										width: 0;
										height: 0;
									}

									.el-tabs__nav-wrap {
										padding: 0;

										.el-tabs__nav {
											width: 100%;
											display: flex;

											.el-tabs__item {
												flex: 1;
												padding: 0;
												text-align: center;
											}
										}
									}
								}

								.el-tabs__content {
									flex: 1;
									padding: 0 20px;

									.el-tab-pane {
										font-size: 0.225rem;

										p {
											margin: 15px 0;
											text-align: left;
										}

										.peoInfo {
											display: flex;
											justify-content: space-between;
											flex-wrap: nowrap;

											p {
												flex: 1;
											}
										}
									}

									// background-color: pink;
								}
							}

						}
					}
				}

				/deep/ .pluginVisiable {
					position: absolute;
					top: 45%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 999999 !important;
					width: 400px;
					height: 200px !important;
					text-align: center;

					.el-dialog {
						width: 100%;
						height: 100% !important;
						margin: 0 !important;

						.el-dialog__header {
							width: 0;
							height: 0;
							display: none;
						}

						.el-dialog__body {
							width: 100%;
							height: 100% !important;
							padding: 0;
							display: flex;
							align-items: center;
							justify-content: center;

							a {
								display: inline-block;
								margin-left: 10px;
								text-align: center;
								color: #009eef;
							}
						}
					}
				}

				.tooltip {
					position: absolute;
					top: 0.125rem;
					left: 0.125rem;
					width: 1.5rem;
					height: 1.5rem;
					z-index: 999;
					font-size: 0.2rem;
					background-color: #fff;
					opacity: 0.8;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.success,
					.stop,
					.work,
					.warning {
						margin-left: 0.25rem;
						line-height: 0.3125rem;

						i {
							display: inline-block;
							width: 0.125rem;
							height: 0.125rem;
							background-color: #0097fe;
							border-radius: 50%;
							margin-right: 0.0625rem;
						}
					}

					.stop {
						i {
							background-color: gray;
						}
					}

					.work {
						i {
							background-color: red;
						}
					}

					.warning {
						i {
							background-color: orange;
						}
					}
				}

				.qiehuanbtn {
					position: absolute;
					bottom: 0.25rem;
					right: 0.25rem;
					display: inline-block;
					width: 0.75rem;
					height: 0.75rem;
					background: url('../../fonts/smaller.png') no-repeat;
					background-size: cover;
					z-index: 99999999999999;
					border-radius: 50%;
					// background-color: skyblue;
				}
			}

			.big-content-right {
				background-color: #fafafa;
				position: relative;
				// display: flex;
				flex: 1;
				color: #0097fe;

				h3 {
					// text-align: ;
					padding: 0 0.25rem;
					margin-top: 0.25rem;
					font-size: 0.3rem;
				}

				/deep/ .right-title {
					padding: 0 0.25rem;
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;

					.right-title-top {
						width: 100%;
						position: relative;
						text-align: center;

						// display: flex;
						// align-items: center;
						h1 {
							margin: 0.0125rem 0;
							font-size: 0.375rem;
						}

						.big-show {
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							border: 1px solid #0097fe;
							padding: 4px 6px;
							font-style: normal;
							border-radius: 6px;
						}

						.qiehuan {
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							display: inline-block;
							width: 0.5rem;
							height: 0.5rem;
							background: url('../../fonts/operation@2x.png') no-repeat;
							background-size: contain;
						}
					}

					.search {
						display: flex;
						width: 100%;
						margin-top: 0.25rem;

						.el-input {
							margin-right: 0.125rem;
						}

						.el-button {
							width: 30%;
							background-color: #0097fe;
							color: #fff;
						}
					}
				}

				/deep/ .carList {
					margin-top: 0.125rem;
					padding: 0 0.25rem;
					// display: flex;
					// background-color: pink;
					height: 76%;

					.el-tabs__header {
						display: flex;
						width: 100%;

						.el-tabs__nav-wrap {
							width: 100%;

							.el-tabs__nav {
								width: 100%;

								.el-tabs__item {
									width: 50%;
									text-align: center;
								}
							}
						}
					}

					.el-tab-pane {
						flex: 1;
						// display: flex;
						width: 100%;

						div {
							// width: 100%;
							height: 0.625rem;
							display: flex;
							display: flex;
							justify-content: space-between;

							p {
								font-size: 0.225rem;
								line-height: 0.625rem;
							}

							.right1 {
								color: #0097fe;
							}

							.right2 {
								color: gray;
							}

							.right3 {
								color: red;
							}

							.right4 {
								color: orange;
							}
						}
					}
				}

				.Nowtime {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 0.625rem;
					font-size: 0.3rem;
					line-height: 0.625rem;
					text-align: center;
					color: red;
				}
			}
		}

		.playfather {
			overflow: hidden;
			width: 440px;
			height: 650px;
			position: absolute;
			// background-color: #111;
			top: 0;
			margin-top: 20px;
			left: 20px;
			// background-color: pink;
			z-index: 999999999999999999999999999999999;

			span {
				padding-left: 10px;
				display: inline-block;
				width: 45.5%;
				height: 50px;
				background-color: #4886ff;
				font-size: 0.2rem;
				line-height: 50px;
				color: #fff;
			}

			.car {
				padding-right: 20px;
			}

			.playWnd {
				z-index: 99999;
				width: 400px;
				height: 600px;

				button {
					z-index: 99999;
					width: 40px;
					height: 40px;
					position: absolute;
					top: 5px;
					right: 45px;
					outline: none;
					border: none;
					background: url('../../images/关 闭.png') no-repeat;
					background-position: center;
				}
			}
		}
	}

	/deep/.el-pagination__jump {
		display: flex !important;
	}

	.dy-sel-input {
		font-size: 0.175rem;

		.title-icon {
			color: #F56C6C;
			margin-right: 0.05rem;

		}
	}

	.el-table .cell {
		text-align: center;
	}
    .fictitious-page{
        background-color: #fff;
        width: 100%;
        visibility: hidden;
    }
</style>
